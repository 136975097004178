import ResponseCodes from '../constants/response-codes.constant';
import { ResponseTO } from '../types/response-to.type';

const Mapper: { [key: number]: string } = {
    [ResponseCodes.INVALID_CARD_PAYMENT]:
        'Datos de tarjeta inválidos. Por favor revísalos e inténtalo nuevamente.',
    [ResponseCodes.CARD_INSUFFICIENT_FUNDS]:
        'La tarjeta no tiene fondus suficientes para esta compra.',
    [ResponseCodes.FRAUDULENT_CARD]:
        'Esta tarjeta no está permita. Por favor usa otra.',
    [ResponseCodes.INVALID_PAYMENT]:
        'Tarjeta inválida. Por favor inténtalo de nuevo o usa otra.',
};

export const mapResponseError = (response: ResponseTO) => {
    if (Mapper[response.code]) return Mapper[response.code];
    return response.message;
};
