import React from 'react';
import styles from './app.module.scss';
import PlanView from './views/plan/plan.view';
import { Route, Routes } from 'react-router-dom';
import { useThemeStore } from './store';

function App() {
    const theme = useThemeStore((state) => state.theme);

    return (
        <main id={styles.app}>
            <Routes>
                <Route path={'/'} element={<PlanView />} />
            </Routes>
        </main>
    );
}

export default App;
