import styles from './main-view.module.scss';
import { CSSProperties, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
    id?: string;
    style?: CSSProperties;
}

const MainView = (props: Props) => {
    return (
        <main id={props.id} style={props.style} className={styles.view}>
            {props.children}
        </main>
    );
};

export default MainView;
