import Input from '../input/input';
import { useEffect, useRef, useState } from 'react';
import party from 'party-js';

import Toggle from '../toggle/toggle';
import CloseIcon from '../../icons/close.icon';
import Divider from '../divider/divider';

import styles from './coupon-code.module.scss';

interface Props {
    onApply: (code: string) => void;
    disabled?: boolean;
    afterApply?: { applied: boolean; errorMessage?: string; discount?: number };
    clearCoupon: () => void;
}

const CouponCode = ({ onApply, disabled, afterApply, clearCoupon }: Props) => {
    const [code, setCode] = useState('');
    const [shown, setShown] = useState(false);
    const [error, setError] = useState('');
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!shown) {
            setCode('');
            setError('');
        }
    }, [shown]);

    useEffect(() => {
        if (afterApply?.applied && divRef.current) {
            party.confetti(divRef.current, { count: 30 });
        }
        if (
            afterApply === undefined ||
            afterApply?.applied ||
            !afterApply?.errorMessage
        ) {
            return setError('');
        }
        if (afterApply.errorMessage) {
            return setError(afterApply.errorMessage);
        }
    }, [afterApply]);

    const isValidCode = (code: string): boolean => {
        return code.trim().length >= 3;
    };

    const onClearCoupon = () => {
        setCode('');
        clearCoupon();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            {!afterApply?.applied && (
                <div
                    className={'row space-between align-center'}
                    style={{ fontSize: 13, gap: 15 }}
                >
                    <span style={{ fontSize: 12 }}>
                        ¿Tienes un código de descuento?
                    </span>
                    <div className={'row align-center'} style={{ gap: 4 }}>
                        <span>No</span>
                        <Toggle default={shown} onToggle={setShown} />
                        <span>Sí</span>
                    </div>
                </div>
            )}

            {shown && (
                <>
                    {afterApply?.applied ? (
                        <div className={styles.couponApplied} ref={divRef}>
                            <span>¡Descuento aplicado!</span>
                            <div
                                className={'row align-center'}
                                style={{ height: '100%', gap: 10 }}
                            >
                                <b>-{afterApply.discount}%</b>
                                <Divider mode={'v'} color={'#128235'} />
                                <CloseIcon
                                    size={16}
                                    color={'#fc7b7b'}
                                    className={styles.clearCoupon}
                                    onClick={onClearCoupon}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.inputContainer}>
                            <Input
                                placeholder={'Introduce tu cupón aquí'}
                                type={'text'}
                                onInput={setCode}
                                disabled={disabled}
                                value={code}
                            />
                            <button
                                onClick={() => onApply(code)}
                                disabled={!isValidCode(code) || disabled}
                            >
                                Aplicar
                            </button>
                        </div>
                    )}
                </>
            )}
            {shown && error && (
                <span className={styles.errorMessage}>Cupón inválido</span>
            )}
        </div>
    );
};

export default CouponCode;
