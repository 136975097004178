import { ResponseTO } from '../types/response-to.type';
import axios, { AxiosError } from 'axios';
import { handleResponseError } from './webservice';
import { Service } from '../types/service.type';
import { BuySubscription } from '../types/buy-subscription.type';

const BASE_URL = process.env.REACT_APP_SUBSCRIPTIONS_API_URL;
const TOKEN = process.env.REACT_APP_PANA_API_TOKEN;

const fetchActives = async (): Promise<ResponseTO<Service[]>> => {
    try {
        const response = await axios.get<ResponseTO>(
            `${BASE_URL}/services/active`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const buySubscription = async (data: BuySubscription) => {
    try {
        const response = await axios.post<ResponseTO>(
            `${BASE_URL}/subscriptions/buy`,
            data,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const serviceSDK = {
    fetchActives,
    buySubscription,
};
