import styles from './credit-card-form.module.scss';
import { useState, useEffect } from 'react';
import { CreditCard } from '../../types/credit-card.type';

interface Props {
    onValid: (cc: CreditCard) => void;
    onError: () => void;
}

const CreditCardForm = (props: Props) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cvc, setCvc] = useState('');

    const [numberValid, setNumberValid] = useState(false);
    const [expValid, setExpValid] = useState(false);
    const [cvcValid, setCvcValid] = useState(false);

    const [numberError, setNumberError] = useState('');
    const [expError, setExpError] = useState('');
    const [cvcError, setCvcError] = useState('');

    useEffect(() => {
        validateCardNumber();
    }, [cardNumber]);

    useEffect(() => {
        validateExpDate();
    }, [expDate]);

    useEffect(() => {
        validateCVC();
    }, [cvc]);

    useEffect(() => {
        if (!numberValid || !expValid || !cvcValid) {
            return props.onError();
        }
        const [expMonth, expYear] = expDate.split(' / ');
        props.onValid({
            number: cardNumber.replaceAll(' ', ''),
            expMonth: expMonth,
            expYear: expYear,
            cvc: cvc,
        });
    }, [numberValid, expValid, cvcValid]);

    const displayNumber = (): string => {
        const formattedNumber: string[] = [];
        cardNumber
            .replaceAll(' ', '')
            .split('')
            .forEach((digit, idx) => {
                if (idx > 0 && idx % 4 === 0) formattedNumber.push(' ');
                formattedNumber.push(digit);
            });
        return formattedNumber.join('');
    };

    const displayExpDate = () => {
        const formattedExpDate: string[] = [];
        expDate
            .replaceAll(' ', '')
            .replaceAll('/', '')
            .split('')
            .forEach((digit, idx) => {
                if (idx === 2) formattedExpDate.push(' / ');
                formattedExpDate.push(digit);
            });

        return formattedExpDate.join('');
    };

    const validateCardNumber = () => {
        const number = cardNumber.replaceAll(' ', '');
        for (const digit of number.split('')) {
            if (!/[0-9]/.test(digit)) return setNumberValid(false);
        }
        if (number.length !== 16 && number.length !== 20)
            return setNumberValid(false);
        setNumberValid(true);
    };

    const validateExpDate = () => {
        const [month, year] = expDate.split(' / ');
        if (!month || !year || month.length !== 2 || year.length !== 2)
            return setExpValid(false);
        for (const digit of expDate.replace(' / ', '').split('')) {
            if (!/[0-9]/.test(digit)) return setExpValid(false);
        }
        if (Number(month) < 1 || Number(month) > 12) return setExpValid(false);
        const currentYear = Number(
            new Date().getFullYear().toString().slice(-2)
        ); // getting current year two-digits
        if (Number(year) < currentYear) return setExpValid(false);

        setExpValid(true);
    };

    const validateCVC = () => {
        if (cvc.length < 3 || cvc.length > 4) return setCvcValid(false);
        for (const digit of cvc.split('')) {
            if (!/[0-9]/.test(digit)) return setCvcValid(false);
        }
        setCvcValid(true);
    };

    return (
        <form id={styles.creditCardForm}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <input
                        className={styles.item}
                        placeholder={'1234 1234 1234 1234'}
                        type={'tel'}
                        inputMode={'numeric'}
                        autoComplete="cc-number"
                        maxLength={19}
                        value={displayNumber()}
                        onChange={(ev) => {
                            setNumberError('');
                            setCardNumber(ev.target.value);
                        }}
                        onBlur={() =>
                            !numberValid &&
                            cardNumber.length > 0 &&
                            setNumberError('Número inválido')
                        }
                    />
                    {!!numberError && (
                        <span className={styles.error}>{numberError}</span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <input
                        className={styles.item}
                        placeholder={'MM / YY'}
                        maxLength={7}
                        value={displayExpDate()}
                        onChange={(ev) => {
                            setExpError('');
                            setExpDate(ev.target.value);
                        }}
                        onBlur={() =>
                            !expValid &&
                            expDate.length > 0 &&
                            setExpError('Fecha inválida')
                        }
                    />
                    {!!expError && (
                        <span className={styles.error}>{expError}</span>
                    )}
                </div>

                <div className={styles.column}>
                    <input
                        className={styles.item}
                        maxLength={4}
                        inputMode={'numeric'}
                        placeholder={'CVC'}
                        onChange={(ev) => {
                            setCvcError('');
                            setCvc(ev.target.value);
                        }}
                        onBlur={() =>
                            !cvcValid &&
                            cvc.length > 0 &&
                            setCvcError('Código inválido')
                        }
                    />
                    {!!cvcError && (
                        <span className={styles.error}>{cvcError}</span>
                    )}
                </div>
            </div>
        </form>
    );
};

export default CreditCardForm;
