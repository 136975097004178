import classNames from 'classnames';
import styles from './payment-error-modal.module.scss';

interface Props {
    shown: boolean;
    message: string;
    onDismiss: () => void;
}

const PaymentErrorModal = (props: Props) => {
    return (
        <div
            className={classNames({
                [styles.paymentErrorModal]: true,
                [styles.shown]: props.shown,
            })}
        >
            <div className={styles.modal}>
                <h4>Error en la compra</h4>
                <span>
                    Ha ocurrido un error en tu pago de la(s) suscripción(es).
                </span>
                <span>Error: {props.message}</span>

                <button onClick={props.onDismiss}>Entendido</button>
            </div>
        </div>
    );
};

export default PaymentErrorModal;
