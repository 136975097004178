import currency from 'currency.js';

const CURRENCY_SYMBOL: Record<string, string> = {
    USD: '$',
    VEF: 'Bs. ',
    VES: 'Bs. ',
};

export const mapCurrencyToSymbol = (currency: string): string => {
    return CURRENCY_SYMBOL[currency];
};

export const applyDiscount = (total: number, discount?: number): number => {
    if (!discount || discount === 0) return total;
    let flatDiscount = currency(discount).divide(100); // convert % discount to numeric
    flatDiscount = currency(total).multiply(flatDiscount); // get total discount from total

    return currency(total).subtract(flatDiscount).value; // get discounted total
};

declare global {
    interface Number {
        withRate(rate?: number): number;
        withDiscount(discount?: number): number;
    }
}

Number.prototype.withDiscount = function (discount?: number): number {
    const total = this as number;
    if (!discount || discount === 0) return total;
    let flatDiscount = currency(discount).divide(100); // convert % discount to numeric
    flatDiscount = currency(total).multiply(flatDiscount); // get total discount from total

    return currency(total).subtract(flatDiscount).value; // get discounted total
};

Number.prototype.withRate = function (rate?: number): number {
    const price = this as number;
    if (!rate || rate === 0) return price;

    return currency(price).multiply(rate).value;
};
