const ResponseCodes = {
    CREATED: 1,
    PROCESS_OK: 0,
    INVALID_REQUEST: -1,
    ENTITY_NOT_FOUND: -2,
    DATABASE_ERROR: -3,
    ENTITY_ALREADY_EXISTS: -4,
    BAD_REQUEST: -5,
    INVALID_CREDENTIALS: -6,
    FORBIDDEN: -7,
    INVALID_CARD_PAYMENT: -8,
    CARD_INSUFFICIENT_FUNDS: -9,
    FRAUDULENT_CARD: -10,
    PENDING_PAYMENT_CONFIRMATION: -11,
    INVALID_PAYMENT: -12,
    INTERNAL_SERVER_ERROR: -999,
};

export default ResponseCodes;
