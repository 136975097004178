import styles from './input.module.scss';
import { useRef, useState } from 'react';
import classNames from 'classnames';

interface Props {
    placeholder?: string;
    type?: string;
    onInput?: (value: string) => void;
    disabled?: boolean;
    value?: string;
}

const Input = (props: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [labelUp, setLabelUp] = useState(false);

    const onFocus = () => {
        setLabelUp(true);
    };

    const onBlur = () => {
        const value = inputRef.current?.value;
        if (value && value.trim().length > 0) return;

        inputRef.current!.value = '';
        setLabelUp(false);
    };

    return (
        <div
            className={styles.container}
            onClick={() => inputRef.current?.focus()}
        >
            <input
                ref={inputRef}
                className={styles.input}
                type={props.type ?? 'text'}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(ev) => props.onInput?.(ev.target.value)}
                disabled={props.disabled}
                value={props.value}
            />
            {props.placeholder && (
                <label className={classNames({ [styles.up]: labelUp })}>
                    {props.placeholder}
                </label>
            )}
        </div>
    );
};

export default Input;
