import styles from './button.module.scss';
import { CSSProperties } from 'react';

interface Props {
    onClick?: () => void;
    text: string;
    disabled?: boolean;
    style?: CSSProperties;
}

const Button = (props: Props) => {
    return (
        <button
            type={'button'}
            className={styles.button}
            onClick={props.onClick}
            disabled={props.disabled}
            style={props.style}
        >
            <span>{props.text}</span>
        </button>
    );
};

export default Button;
