import styles from './plan.module.scss';
import MainView from '../../components/main-view/main-view';
import { usePaymentStore, usePlanStore, useServiceStore } from '../../store';
import { JSX, useEffect, useRef, useState } from 'react';
import Card from '../../components/card/card';
import Button from '../../components/button/button';
import { Service } from '../../types/service.type';
import classNames from 'classnames';
import { Beneficiary } from '../../types/beneficiary.type';
import { mapCurrencyToSymbol } from '../../utils/currency.util';
import PaymentMethodItem from '../../components/payment-method-item/payment-method-item';
import CardEditButton from '../../components/card-edit-button/card-edit-button';
import PaymentForm from '../../components/payment-form/payment-form';
import { PaymentFormData } from '../../types/payment-form-data.type';
import SuccessfulIcon from '../../assets/images/successful.svg';
import BeneficiariesCard from '../../components/beneficiaries-card/beneficiaries-card';
import { Plan } from '../../types/plan.type';
import { PaymentMethod } from '../../types/payment-config.type';
import ResponseCodes from '../../constants/response-codes.constant';
import PaymentErrorModal from '../../components/payment-error-modal/payment-error-modal';
import useQuery from '../../hooks/use-query.hook';
import { Analytics } from '../../utils/analytics.util';
import { User } from '../../types/user.type';
import { userSDK } from '../../webservices/user.webservice';
import { serviceSDK } from '../../webservices/service.webservice';
import { mapResponseError } from '../../utils/error-mapper.util';
import party from 'party-js';

const PlanView = () => {
    const queryParams = useQuery();
    const confettiImgRef = useRef<HTMLImageElement>(null);

    const serviceState = useServiceStore();
    const planState = usePlanStore();
    const paymentState = usePaymentStore();

    const [currentStep, setCurrentStep] = useState(1);
    const [errorModal, setErrorModal] = useState({
        shown: false,
        message: '',
    });
    const [knownUser, setKnownUser] = useState<User>();
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        manageQueryParams();
    }, [queryParams, serviceState.services]);

    useEffect(() => {
        paymentState.fetchMethods();
        serviceState.fetch();
        Analytics.track('PageVisited', { action: 'PageLoaded' });
    }, []);

    useEffect(() => {
        confettiImgRef.current &&
            paymentState.paymentDone &&
            party.confetti(confettiImgRef.current, { count: 100 });
    }, [paymentState.paymentDone]);

    const manageQueryParams = () => {
        getPlanParam();
        getUserParam();
    };

    const getPlanParam = () => {
        if (!queryParams.has('planid')) return;
        const planId = Number(queryParams.get('planid'));
        if (isNaN(planId)) return;

        let selectedPlan;
        for (const service of serviceState.services) {
            for (const plan of service.plans!) {
                if (plan.id === planId) {
                    selectedPlan = { ...plan, serviceCode: service.code };
                    break;
                }
            }
        }
        selectedPlan && selectPlan(selectedPlan);
    };

    const getUserParam = () => {
        if (!queryParams.has('userid')) return;
        const userId = Number(queryParams.get('userid'));
        if (isNaN(userId)) return;
        !knownUser && fetchUserData(userId);
    };

    const selectPlan = (plan: Plan) => {
        const { price, currency } = plan.prices[0];
        planState.selectPlan(plan);
        planState.setPricing({ price, currency });
        setCurrentStep(currentStep + 1);
        Analytics.track('PlanSelected', {
            action: 'ClickOnItem',
            plan: { id: plan.id, name: (plan as any).name },
        });
    };

    const fetchUserData = async (userId: number) => {
        const response = await userSDK.getById(userId);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('[!] Error getting user:', response);
        }
        setKnownUser(response.data);
    };

    const addBeneficiaries = (beneficiaries: Beneficiary[]) => {
        planState.addBeneficiaries(beneficiaries);
        setCurrentStep(currentStep + 1);

        const beneficiary = beneficiaries[0];
        Analytics.track('BeneficiariesAdded', {
            action: 'ButtonPressed',
            user: {
                fullName: beneficiary.fullName,
                email: beneficiary.email,
            },
        });
    };

    const selectPaymentMethod = (methodCode: string) => {
        const method = paymentState.methods.find((m) => m.code === methodCode);
        if (!method) return;
        paymentState.selectMethod(method);
        setCurrentStep(currentStep + 1);
        Analytics.track('PaymentMethodSelected', {
            action: 'ClickOnItem',
            paymentMethod: {
                name: method.displayName,
                currency: method.currency,
            },
        });
    };

    const submitPayment = async (payment: PaymentFormData) => {
        const buyRequest = {
            payment: {
                ...payment,
                methodId: paymentState.selectedMethod!.id,
                couponCode: payment.couponCode,
            },
            beneficiaries: planState.beneficiaries,
            planId: planState.selectedPlan!.id,
        };
        const response = await serviceSDK.buySubscription(buyRequest);
        if (
            ![
                ResponseCodes.PROCESS_OK,
                ResponseCodes.PENDING_PAYMENT_CONFIRMATION,
            ].includes(response.code)
        ) {
            Analytics.track('ErrorSubmittingPayment', {
                action: 'ButtonPressed',
                error: response,
            });
            return setErrorModal({
                shown: true,
                message: mapResponseError(response),
            });
        }
        paymentState.setPaymentDone(true);
        Analytics.track('PaymentSubmitted', {
            action: 'ButtonPressed',
            paymentAmount: payment.amount,
        });
    };

    const renderPlansCard = (
        services: Service[],
        pricingType: string,
        currentStep: number,
        shown: boolean
    ): JSX.Element => {
        const plans = services.map((service) => {
            const plan = service.plans?.find(
                (plan) => plan.type === pricingType
            );
            const { currency, price } = plan!.prices[0];
            return {
                ...plan!,
                price,
                currency,
                name: service.name,
                serviceCode: service.code,
            };
        });
        return (
            <Card
                className={classNames({
                    [styles.card]: true,
                    [styles.shown]: shown,
                })}
            >
                <CardEditButton
                    shown={currentStep !== 1}
                    onClick={() => setCurrentStep(1)}
                />
                <span
                    className={classNames({
                        [styles.cardTitle]: true,
                        [styles.active]: currentStep === 1,
                    })}
                >
                    Escoge el que más se adapte a ti:
                </span>
                <div className={styles.planList}>
                    {plans.map((plan) => (
                        <div
                            key={plan!.id}
                            className={styles.plan}
                            onClick={() => selectPlan(plan!)}
                        >
                            <div className={'column justify-center'}>
                                <span className={styles.name}>
                                    {plan!.name}
                                </span>
                                <span className={styles.description}>
                                    {plan!.description}
                                </span>
                            </div>
                            <div className={'column justify-center'}>
                                <span className={styles.price}>
                                    {mapCurrencyToSymbol(plan.currency)}{' '}
                                    {plan.price}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                {/*<PaymentTypeToggle onType={planState.selectType} />*/}
            </Card>
        );
    };

    const renderBeneficiaryCard = (
        currentStep: number,
        shown: boolean
    ): JSX.Element => {
        return (
            <BeneficiariesCard
                plan={planState.selectedPlan}
                currentStep={currentStep}
                shown={currentStep > 1 && shown}
                rollUp={currentStep > 2}
                submitBeneficiaries={addBeneficiaries}
                goBack={() => setCurrentStep(2)}
                knownUser={knownUser}
            />
        );
    };

    const renderPaymentMethodsCard = (
        currentStep: number,
        priceType: string,
        methods: PaymentMethod[],
        shown: boolean
    ): JSX.Element => {
        return (
            <Card
                className={classNames({
                    [styles.card]: true,
                    [styles.shown]: currentStep > 2 && shown,
                    [styles.paymentMethods]: true,
                })}
            >
                <CardEditButton
                    shown={currentStep !== 3}
                    onClick={() => setCurrentStep(3)}
                />
                <span
                    className={classNames({
                        [styles.cardTitle]: true,
                        [styles.active]: currentStep === 3,
                    })}
                >
                    ¿Qué método de pago te va mejor?
                </span>
                <ul className={styles.paymentMethodsList}>
                    {methods.map((method, idx) => (
                        <PaymentMethodItem
                            key={idx}
                            active={
                                method.code === 'TDCSTRIPE' ||
                                priceType === 'one_time'
                            }
                            name={method.displayName}
                            description={method.description ?? ''}
                            onClick={() => selectPaymentMethod(method.code)}
                        />
                    ))}
                </ul>

                {/*
                <i className={styles.explanatory}>
                    Los métodos <b>Zelle</b> y <b>Pago Móvil</b> sólo aplican
                    para pagos de planes anuales.
                </i>
                */}
            </Card>
        );
    };

    const renderPaymentFormCard = (
        currentStep: number,
        paymentData: {
            price: number;
            method?: PaymentMethod;
            type: string;
        },
        shown: boolean
    ): JSX.Element => {
        const currency = paymentState.currencies.find(
            (currency) => currency.currency === paymentData.method?.currency
        );
        return (
            <Card
                className={classNames({
                    [styles.card]: true,
                    [styles.shown]: currentStep > 3 && shown,
                    [styles.paymentForm]: true,
                })}
            >
                <span
                    className={classNames({
                        [styles.cardTitle]: true,
                        [styles.active]: true,
                    })}
                >
                    Monto total:{' '}
                    {mapCurrencyToSymbol(currency?.currency ?? 'USD')}
                    {paymentData.price.withRate(currency?.rate ?? 1).toFixed(2)}
                </span>
                {!currency ? null : (
                    <PaymentForm
                        method={paymentData.method!}
                        amount={paymentData.price}
                        currency={currency.currency}
                        type={paymentData.type}
                        onPaymentData={submitPayment}
                        onDiscount={setDiscount}
                    />
                )}
            </Card>
        );
    };

    const renderAllDoneCard = (shown: boolean): JSX.Element => {
        return (
            <Card
                className={classNames({
                    [styles.card]: true,
                    [styles.allDone]: true,
                    [styles.shown]: shown,
                })}
            >
                <h4>¡Felicidades!</h4>
                <img src={SuccessfulIcon} ref={confettiImgRef} />
                {paymentState.selectedMethod?.code === 'cc' ? (
                    <p>
                        Ya estás suscrito a PANA y hemos enviado toda la
                        información de tu suscripción a tu correo electrónico
                        🙌🏻.
                    </p>
                ) : (
                    <p>
                        Hemos registrado tu pago existosamente y te haremos
                        saber vía correo electrónico cuando tu suscripción esté
                        activa 🙌🏻
                    </p>
                )}
                <Button
                    text={'Finalizar'}
                    onClick={() =>
                        window.location.assign('https://home.panatech.io')
                    }
                />
            </Card>
        );
    };

    return (
        <MainView>
            <div className={styles.container}>
                <aside>
                    <h4>Casi todo listo para tener tu suscripción PANA</h4>
                    <span>
                        Coméntanos sobre ti para preparar tu suscripción a la
                        medida.
                    </span>
                </aside>
                <section>
                    <div className={styles.cardsContainer}>
                        {renderPlansCard(
                            serviceState.services,
                            planState.purchaseType,
                            currentStep,
                            !paymentState.paymentDone
                        )}
                        {renderBeneficiaryCard(
                            currentStep,
                            !paymentState.paymentDone
                        )}
                        {renderPaymentMethodsCard(
                            currentStep,
                            planState.purchaseType,
                            paymentState.methods,
                            !paymentState.paymentDone
                        )}
                        {renderPaymentFormCard(
                            currentStep,
                            {
                                price: planState.price.withDiscount(discount),
                                method: paymentState.selectedMethod,
                                type: planState.purchaseType,
                            },
                            !paymentState.paymentDone
                        )}
                        {renderAllDoneCard(paymentState.paymentDone)}
                    </div>
                </section>
                <PaymentErrorModal
                    shown={errorModal.shown}
                    message={errorModal.message}
                    onDismiss={() =>
                        setErrorModal({ shown: false, message: '' })
                    }
                />
            </div>
        </MainView>
    );
};

export default PlanView;
