import styles from './card.module.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
    className?: string;
}

const Card = (props: Props) => {
    return (
        <div className={classNames([styles.card, props.className])}>
            {props.children}
        </div>
    );
};

export default Card;
