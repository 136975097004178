import { AxiosError } from 'axios';
import { ResponseTO } from '../types/response-to.type';

const mapQueryParams = (params: { [key: string]: any } | undefined): string => {
    let inlineParams = '';
    if (!params) return inlineParams;
    const keys = Object.keys(params);
    let idx = 0;

    for (const key of keys) {
        if (!params[key] || params[key].toString().trim() === '') {
            continue;
        }

        if (idx === 0) {
            inlineParams += `?${key}=${params[key]}`;
        } else {
            inlineParams += `&${key}=${params[key]}`;
        }

        idx++;
    }

    return inlineParams;
};

const handleResponseError = (error: AxiosError): ResponseTO => {
    if (!error.response) {
        console.error('[!] Internet connection error');
        return {
            code: -999,
            message: 'Internet connection error',
            data: null,
        };
    }
    console.error('http error:', error.response);

    return error.response.data as ResponseTO;
};

export { mapQueryParams, handleResponseError };
