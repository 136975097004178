import styles from './nid-input.module.scss';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import ArrowDownIcon from '../../icons/arrow-down.icon';

enum DocumentTypes {
    V = 'V',
    E = 'E',
    P = 'P',
}

interface Props {
    placeholder?: string;
    onInput?: (nid: string) => void;
}

const NidInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [type, setType] = useState(DocumentTypes.V);
    const [typesShown, setTypesShown] = useState(false);
    const [labelUp, setLabelUp] = useState(false);
    const [document, setDocument] = useState('');

    const onFocus = () => {
        setLabelUp(true);
    };

    const onBlur = () => {
        const value = inputRef.current?.value;
        if (value && value.trim().length > 0) return;

        inputRef.current!.value = '';
        setLabelUp(false);
    };

    const selectType = (type: DocumentTypes) => {
        setType(type);
        setTypesShown(false);
        props.onInput?.(`${type}-${document}`);
    };

    const onInput = (value: string) => {
        setDocument(value);
        props.onInput?.(`${type}-${value}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.types} onClick={() => setTypesShown(true)}>
                <span>{type}</span>
                <ArrowDownIcon />
            </div>
            <span>-</span>
            <div className={styles.inputContainer}>
                <input
                    ref={inputRef}
                    className={styles.input}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={(ev) => onInput(ev.target.value)}
                />
                {props.placeholder && (
                    <label className={classNames({ [styles.up]: labelUp })}>
                        {props.placeholder}
                    </label>
                )}
            </div>
            {typesShown && (
                <ul className={styles.typesModal}>
                    <li onClick={() => selectType(DocumentTypes.V)}>V</li>
                    <li onClick={() => selectType(DocumentTypes.E)}>E</li>
                    <li onClick={() => selectType(DocumentTypes.P)}>P</li>
                </ul>
            )}
        </div>
    );
};

export default NidInput;
