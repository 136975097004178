import styles from './link.module.scss';

interface Props {
    text: string;
    goTo: string;
    blank?: boolean;
}

const Link = (props: Props) => {
    return (
        <a
            href={props.goTo}
            className={styles.link}
            target={props.blank ? '_blank' : undefined}
        >
            {props.text}
        </a>
    );
};

export default Link;
