import mixpanel from 'mixpanel-browser';

interface TrackingData {
    action: string;
    user?: { fullName: string; email: string };
    plan?: { id: number; name: string };
    paymentMethod?: { name: string; currency: string };
    paymentAmount?: number;
    error?: any;
}

const track = (event: string, data: TrackingData) => {
    mixpanel.track(event, data);
};

export const Analytics = {
    track,
};
