interface Props {
    mode?: 'v' | 'h';
    thickness?: number;
    color?: string;
}

const HDivider = ({ mode, color, thickness }: Props) => {
    return (
        <span
            style={{
                width: mode === 'h' ? '100%' : thickness ?? 1,
                height: mode === 'v' ? '100%' : thickness ?? 1,
                backgroundColor: color ?? '#d8dee7',
            }}
        />
    );
};

export default HDivider;
