import { create } from 'zustand';
import { serviceSDK } from './webservices/service.webservice';
import { Service } from './types/service.type';
import ResponseCodes from './constants/response-codes.constant';
import { Beneficiary } from './types/beneficiary.type';
import { Plan } from './types/plan.type';
import { PaymentMethod } from './types/payment-config.type';
import { paymentsSDK } from './webservices/payment.webservice';

interface ThemeState {
    theme: 'light' | 'dark';
    toggle: () => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
    theme: 'light',
    toggle: () =>
        set((state: any) => ({
            theme: state.theme === 'light' ? 'dark' : 'light',
        })),
}));

interface ServiceState {
    services: Service[];
    fetch: () => void;
}

export const useServiceStore = create<ServiceState>((set) => ({
    services: [],
    fetch: async () => {
        const response = await serviceSDK.fetchActives();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            set({ services: [] });
        }
        const activeServices = response.data.filter(
            (service) => service.perks.length > 0
        );
        // Sorting services descending by priority
        activeServices.sort((a, b) => a.priority - b.priority);
        set({ services: activeServices });
    },
}));

interface PlanState {
    selectedPlan?: Plan;
    purchaseType: string;
    beneficiaries: Beneficiary[];
    price: number;
    currency: string;
    selectPlan: (plan: Plan) => void;
    addBeneficiaries: (beneficiaries: Beneficiary[]) => void;
    selectType: (type: string) => void;
    setPricing: (pricing: { price: number; currency: string }) => void;
}

export const usePlanStore = create<PlanState>((set) => ({
    selectedPlan: undefined,
    purchaseType: 'one_time',
    paymentMethods: [],
    selectedPaymentMethod: undefined,
    beneficiaries: [],
    price: 0,
    currency: 'USD',
    paymentDone: false,
    selectPlan: (plan: Plan) => set({ selectedPlan: plan }),
    addBeneficiaries: (beneficiaries: Beneficiary[]) => set({ beneficiaries }),
    selectType: (type: string) => set({ purchaseType: type }),
    setPricing: (pricing: { price: number; currency: string }) =>
        set({ price: pricing.price, currency: pricing.currency }),
}));

interface PaymentState {
    methods: PaymentMethod[];
    selectedMethod?: PaymentMethod;
    currencies: { currency: string; rate: number }[];
    paymentDone: boolean;
    fetchMethods: () => void;
    selectMethod: (method: PaymentMethod) => void;
    setPaymentDone: (done: boolean) => void;
}

export const usePaymentStore = create<PaymentState>((set) => ({
    methods: [],
    selectedMethod: undefined,
    currencies: [],
    paymentDone: false,
    fetchMethods: async () => {
        const response = await paymentsSDK.fetchConfig();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return;
        }
        const { config, methods } = response.data;
        set({ currencies: config, methods: methods });
    },
    selectMethod: (method: PaymentMethod) => set({ selectedMethod: method }),
    setPaymentDone: (done: boolean) => set({ paymentDone: done }),
}));
