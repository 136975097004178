import { ResponseTO } from '../types/response-to.type';
import axios, { AxiosError } from 'axios';
import { handleResponseError } from './webservice';
import { User } from '../types/user.type';

const BASE_URL = process.env.REACT_APP_USERS_API_URL;
const TOKEN = process.env.REACT_APP_PANA_API_TOKEN;

const getById = async (id: number): Promise<ResponseTO<User>> => {
    try {
        const response = await axios.get<ResponseTO>(
            `${BASE_URL}/users/${id}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const userSDK = {
    getById,
};
