interface Props {
    size?: number;
    color?: string;
}

const ArrowDownIcon = (props: Props) => {
    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke={props.color ?? '#0dc648'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M6 9l6 6l6 -6" />
        </svg>
    );
};

export default ArrowDownIcon;
