interface Props {
    size?: number;
    color?: string;
}

const PencilIcon = (props: Props) => {
    return (
        <svg width={props.size ?? 15} height={props.size ?? 15} fill="none">
            <path
                d="M0 12.05v2.533c0 .233.183.417.417.417H2.95a.39.39 0 0 0 .292-.125l9.1-9.1L9.215 2.66l-9.1 9.1a.41.41 0 0 0-.125.3zm14.756-8.682A.83.83 0 0 0 15 2.781a.83.83 0 0 0-.244-.587l-1.95-1.95A.83.83 0 0 0 12.219 0a.83.83 0 0 0-.318.063.83.83 0 0 0-.269.18l-1.525 1.525 3.125 3.125 1.525-1.525z"
                fill={props.color ?? '#0dc648'}
            />
        </svg>
    );
};

export default PencilIcon;
