import styles from './payment-method-item.module.scss';
import classNames from 'classnames';

interface Props {
    active: boolean;
    name: string;
    description: string;
    onClick: () => void;
}

const PaymentMethodItem = (props: Props) => {
    const onClick = () => {
        props.active && props.onClick();
    };

    return (
        <li
            className={classNames({
                [styles.item]: true,
                [styles.active]: props.active,
            })}
            onClick={onClick}
        >
            <span className={styles.name}>{props.name}</span>
            <span className={styles.description}>{props.description}</span>
        </li>
    );
};

export default PaymentMethodItem;
