import styles from './payment-form.module.scss';
import { JSX, useEffect, useState } from 'react';
import Input from '../input/input';
import { mapCurrencyToSymbol } from '../../utils/currency.util';
import Button from '../button/button';
import * as yup from 'yup';
import CreditCardForm from '../credit-card-form/credit-card-form';
import { PaymentFormData } from '../../types/payment-form-data.type';
import { CreditCard } from '../../types/credit-card.type';
import { PaymentMethod } from '../../types/payment-config.type';
import CouponCode from '../coupon-code/coupon-code';
import { paymentsSDK } from '../../webservices/payment.webservice';
import ResponseCodes from '../../constants/response-codes.constant';

interface Props {
    currency: string;
    amount: number;
    method: PaymentMethod;
    type: string;
    onPaymentData: (payment: PaymentFormData) => void;
    onDiscount?: (discount: number) => void;
}

const PaymentForm = (props: Props) => {
    const [buttonProps, setButtonProps] = useState({
        disabled: true,
        text: 'Confirmar',
    });

    const [zellePayment, setZellePayment] = useState('');
    const [mobilePayment, setMobilePayment] = useState('');
    const [creditCard, setCreditCard] = useState<CreditCard>({
        number: '',
        expMonth: '',
        expYear: '',
        cvc: '',
    });
    const [coupon, setCoupon] = useState<{
        code?: string;
        disabled: boolean;
        afterApply?: {
            applied: boolean;
            errorMessage: string;
            discount?: number;
        };
    }>({
        code: undefined,
        disabled: false,
        afterApply: undefined,
    });

    useEffect(() => {
        validatePayment(props.method);
    }, [zellePayment, mobilePayment, creditCard]);

    const validatePayment = (method: PaymentMethod) => {
        if (!method) return;
        switch (method.code) {
            case 'TDCSTRIPE':
                return setValidCreditCard(true);
            case 'TZELLE':
                return validateZelle();
            case 'PMBVC':
            default:
                return validateMobilePayment();
        }
    };

    const validateZelle = async () => {
        const schema = yup.string().min(3).required();
        const validation = await schema.isValid(zellePayment);
        setButtonProps({ text: 'Confirmar', disabled: !validation });
    };

    const validateMobilePayment = async () => {
        const schema = yup.string().min(6).required();
        const validation = await schema.isValid(mobilePayment);
        setButtonProps({ text: 'Confirmar', disabled: !validation });
    };

    const setValidCreditCard = (valid: boolean) => {
        setButtonProps({ text: 'Confirmar', disabled: !valid });
    };

    const sendPaymentRequest = () => {
        setButtonProps({ text: 'Cargando...', disabled: true });
        const paymentData: PaymentFormData = {
            amount: props.amount,
            currency: props.currency,
        };
        if (props.method.code === 'TDCSTRIPE') {
            paymentData.card = creditCard;
        } else {
            paymentData.referenceCode = zellePayment || mobilePayment;
        }
        paymentData.couponCode = coupon.code;
        props.onPaymentData(paymentData);
    };

    const renderMethodForm = (method: PaymentMethod): JSX.Element | null => {
        if (!method) return null;
        switch (method.code) {
            case 'TDCSTRIPE':
                return renderCreditCard();
            case 'TZELLE':
                return renderZelle();
            case 'PMBVC':
            default:
                return renderMobilePayment();
        }
    };

    const renderZelle = (): JSX.Element => {
        return (
            <form className={styles.paymentForm}>
                <div className={styles.info}>
                    <span>A nuestra cuenta Zelle:</span>
                    <span className={styles.zelleEmail}>fin@panatech.io</span>
                    <span className={styles.companyName}>
                        PANA CORPORATE SERVICES, LLC.
                    </span>
                </div>
                <Input
                    placeholder={'Nombre del titular en Zelle'}
                    onInput={setZellePayment}
                />
            </form>
        );
    };

    const renderMobilePayment = (): JSX.Element => {
        return (
            <form className={styles.paymentForm}>
                <div className={styles.info}>
                    <span>Utilizando Pago Móvil:</span>
                    <b>Banco Venezolano de Crédito</b>
                    <b>J-410713747</b>
                    <b>0414-2259800</b>
                </div>
                <Input
                    placeholder={'Número de referencia'}
                    onInput={setMobilePayment}
                />
            </form>
        );
    };

    const renderCreditCard = (): JSX.Element => {
        return (
            <div className={styles.paymentForm}>
                <div className={styles.info}>
                    <span>Introduce tu tarjeta de crédito:</span>
                </div>
                <CreditCardForm
                    onError={() => setValidCreditCard(false)}
                    onValid={setCreditCard}
                />
            </div>
        );
    };

    const checkCouponCode = async (code: string) => {
        setCoupon((prev) => ({
            ...prev,
            disabled: true,
            afterApply: { applied: false, errorMessage: '' },
        }));
        const response = await paymentsSDK.checkCouponCode(code.trim());
        setCoupon((prev) => ({ ...prev, disabled: false }));
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return setCoupon({
                code: undefined,
                disabled: false,
                afterApply: { applied: false, errorMessage: 'Coupón inválido' },
            });
        }
        const coupon = response.data;
        if (coupon.quantity < 1 || coupon.hasExpired) {
            return setCoupon({
                code: undefined,
                disabled: false,
                afterApply: { applied: false, errorMessage: 'Coupón inválido' },
            });
        }
        setCoupon({
            code,
            disabled: false,
            afterApply: {
                applied: true,
                errorMessage: '',
                discount: coupon.discount,
            },
        });
        props.onDiscount?.(coupon.discount);
    };

    const clearCoupon = () => {
        setCoupon({
            code: undefined,
            disabled: false,
            afterApply: undefined,
        });
        props.onDiscount?.(0);
    };

    return (
        <div className={styles.container}>
            {renderMethodForm(props.method)}
            <CouponCode
                onApply={checkCouponCode}
                disabled={coupon.disabled}
                afterApply={coupon.afterApply}
                clearCoupon={clearCoupon}
            />
            <footer>
                <div className={'column'}>
                    {props.type === 'recurring_licensed' && (
                        <b className={styles.recurringExplanatory}>
                            Se te cobrarán {mapCurrencyToSymbol(props.currency)}
                            {props.amount.toFixed(2)} el primer día de cada mes.
                        </b>
                    )}
                    <span>Al pagar estás aceptando nuestros</span>
                    <a
                        href={process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK}
                        target={'_blank'}
                    >
                        Términos y Condiciones
                    </a>
                </div>
                <Button
                    text={buttonProps.text}
                    disabled={buttonProps.disabled}
                    onClick={sendPaymentRequest}
                />
            </footer>
        </div>
    );
};

export default PaymentForm;
