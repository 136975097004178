import { ResponseTO } from '../types/response-to.type';
import axios, { AxiosError } from 'axios';
import { handleResponseError } from './webservice';
import { PaymentConfig } from '../types/payment-config.type';
import { Coupon } from '../types/coupon.type';

const BASE_URL = process.env.REACT_APP_PAYMENTS_API_URL;
const TOKEN = process.env.REACT_APP_PANA_API_TOKEN;

const fetchConfig = async (): Promise<ResponseTO<PaymentConfig>> => {
    try {
        const response = await axios.get<ResponseTO>(
            `${BASE_URL}/payments/config`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const checkCouponCode = async (code: string): Promise<ResponseTO<Coupon>> => {
    try {
        const response = await axios.get<ResponseTO>(
            `${BASE_URL}/coupons/codes/${code}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const paymentsSDK = {
    fetchConfig,
    checkCouponCode,
};
