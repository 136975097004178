import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
});
mixpanel.identify(uuidv4());

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
