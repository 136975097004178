import classNames from 'classnames';
import styles from './card-edit-button.module.scss';
import PencilIcon from '../../icons/pencil.icon';

interface Props {
    shown: boolean;
    onClick: () => void;
}

const CardEditButton = (props: Props) => {
    return (
        <div
            className={classNames({
                [styles.editButton]: true,
                [styles.shown]: props.shown,
            })}
            onClick={props.onClick}
        >
            <span>Editar</span>
            <PencilIcon color={'#0dc648'} />
        </div>
    );
};

export default CardEditButton;
